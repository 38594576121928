<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-toolbar-title>修改密码</v-toolbar-title>
      <v-text-field v-model="form.key" :rules="emailRules" label="E-mail" required></v-text-field>
      <v-text-field v-model="form.password" :rules="passWordRules" label="新密码" type="password" required></v-text-field>
      <v-text-field v-model="form.code" :rules="[v => !!v || '不能为空']" label="验证码" required>
        <template v-slot:append-outer>
          <v-btn rounded :color="btnColor" class="mr-4" :disabled="canClick" @click="getCode">
            {{codeMsg}}
          </v-btn>
        </template>
      </v-text-field>
      <v-btn :disabled="!valid" style="margin: 13px 0;" block color="success" class="mr-4" @click="setOk">
        完成
      </v-btn>
      <v-card-actions>
        <v-btn color="#666666" text @click="$router.replace('/reg')">注册</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="#666666" text class="mr-4" @click="$router.replace('/login')">
          登录
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
  import { tips, post } from '@/facade'
  export default {
    name: 'password',
    data() {
      return {
        valid: true,
        form: { type: 'email', password: "" },
        codeMsg: "验证码",
        btnColor: 'success',
        totalTime: 60,
        canClick: false,
        clock: null,
        emailRules: [
          v => !!v || '不能为空',
          v => /.+@.+\..+/.test(v) || '请输入正确的邮箱格式',
        ],
        passWordRules: [
          v => !!v || '不能为空',
          v => (v.length >= 6 && v.length <= 10) || '请输入6-10位密码',
        ]

      }
    },
    methods: {
      /**完成 */
      async setOk() {
        const value = this.$refs.form.validate()
        if (value) {
          const form = {
            type: 'email',
            key: this.form.key,
            password: this.form.password,
            code: this.form.code,
          }
          const data = await post('/user/pwd', form)
          if (data.code == 'ok') {
            tips('success', '修改成功');
            this.$router.replace('/login')
          } else {
            tips('error', data.message);
          }
        }

      },
      /**获取验证码 */
      async getCode() {
        if (this.form.key) {
          if (this.canClick) return
          this.canClick = true
          this.codeMsg = this.totalTime + 's后重新发送'
          const _self = this
          _self.clock = setInterval(() => {
            _self.totalTime--
            _self.codeMsg = _self.totalTime + 's后重新发送'
            if (this.totalTime < 0) {
              clearInterval(_self.clock)
              _self.clock = null
              _self.codeMsg = '发送验证码'
              _self.totalTime = 60
              _self.canClick = false //这里重新开启
            }
          }, 1000)
          const data = await post('/user/auth', { method: '身份验证', type: 'email', key: this.form.key })
          if (data.code == 'ok') {
            tips('success', '验证码已发送')
          } else {
            tips('error', data.message)
          }
        } else {
          tips('error', '请输入邮箱')
        }
      }

    }
  }
</script>
<style scoped>
  #password {
    background: #e9ecef;
  }
</style>